import React from "react";
import Layout from "../components/layout.js"
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import grey from '@material-ui/core/colors/grey';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

const faqTheme = createMuiTheme({
  palette: {
    secondary: {
      main: grey[100],
    }
  },
});

const i18n = {
  'cn': {
    api: 'API接口文档',
    apiFrontEndExternal: 'FrontEnd REST API在线文档（详细版本，需要科学上网）',
    apiFrontEndExternalURL: 'https://frontendcn.docs.apiary.io/',
    apiFrontEndInternal: 'FrontEnd REST API本地文档（简略版本）',
    apiFrontEndInternalURL: '/api/frontend_api_cn.html',
    apiFrontEndRaw: 'FrontEnd REST API原始文档（API Blueprint）',
    apiFrontEndRawURL: '/api/frontend_api_cn.apib',
    community: '社区支持',
    communityDescription: '欢迎加入QQ群：Nano Cloud(819161083)，及时获取最新动态与技术支持',
    title: '文档与支持',
    faqs: '常见问题',
    links: '资源链接',
    resources: [
      {
        name: '快速入门手册（必读）',
        url: 'https://nanocloud.readthedocs.io/projects/guide/zh_CN/latest/'
      },
      {
        name: '功能特性详解',
        url: '/features'
      },
      {
        name: '系统白皮书(pdf)',
        url: '/files/nano_white_paper.pdf'
      },
      {
        name: 'Git库',
        url: 'https://github.com/project-nano'
      },
      {
        name: 'Wiki',
        url: 'https://github.com/project-nano/releases/wiki'
      }
    ],
    questions: [
      {
        title: 'Nano能做什么？',
        answer: (
          <div>
            <p>
            Nano是虚拟化云平台，可以把你的物理服务器集群转换为计算资源池，并在之上动态分配管理云主机，有效提高IT设施的资源利用效率，节省管理成本。
            </p>
            <p>
            Nano提供从资源监控、实例管理、镜像到快照等一系列便捷管理手段，提高运维人员的工作效率。Nano提供完整的API和纯HTML5的管理页面，你可以定制自己的云平台产品，或者集成到现有平台当中。
            </p>
          </div>
        )
      },
      {
        title: '安装Nano有什么要求？',
        answer: (
          <ul>
            <li>至少一台支持虚拟化的X86服务器或者打开嵌套虚拟化的虚拟机</li>
            <li>2核4G内存50GB磁盘和一个网络设备</li>
            <li>CentOS 7 Minimal</li>
            <li>操作系统安装完成并且网络就绪</li>
          </ul>
        )
      },
      {
        title: '如何安装Nano？',
        answer: (
          <div>详见<Link href='/quick_start'>快速开始</Link>，通常安装时间为三分钟左右</div>
        )
      },
      {
        title: '能否在虚拟机安装Nano？',
        answer: (
          <div>
            <p>
            理论上支持嵌套虚拟化的技术都可以使用，已测试虚拟化技术：
            </p>
            <ul>
              <li>VMware Station，可以安装，需要打开CPU的嵌套虚拟化开关</li>
              <li>VMware ESXi，可以安装，需要启用网络混杂模式</li>
              <li>VirtualBox，不可安装</li>
            </ul>
          </div>
        )
      },
      {
        title: 'Nano安装过程中网络中断',
        answer: (
          <div>
            Installer安装过程中，会构建桥接网络并且重启网络服务，对于常见的Dell系列服务器和VMware实例，该操作不会中断网络连接。但是确实有部分服务器可能因为网络驱动原因，会导致网络中断，这种情况请使用服务器的IPMI或者类似远程管理界面进行安装，而不要使用SSH。
          </div>
        )
      },
      {
        title: 'Installer或者Cell启动时提示"no default route available"',
        answer: (
          <div>
            Nano所在服务器必须配置好默认路由才能正常工作，如果模块检测不到默认路由，则需要手工配置再启动服务。<br/>
            假设网络中默认网关为192.168.1.1，则在安装Nano的服务器上执行指令：<br/>
            <Box bgcolor='secondary.main'>
              <blockquote>
                $ ip route add default via 192.168.1.1
              </blockquote>
            </Box>
          </div>
        )
      },
      {
        title: 'Installer提示"firewalld service stopped/disabled"',
        answer: (
          <div>
            Nano需要在防火墙设置允许组播协议后，才能正常工作，请打开firewalld服务并设置为开机启动状态。你也可以输入yes强制继续安装，但是安装完成的集群很可能无法正常工作。
          </div>
        )
      },
      {
        title: 'Installer安装过程中无法找到宿主机的可用网卡',
        answer: (
          <div>
            安装过程中，Installer会在宿主机目前网卡清单中，选择以‘em’开头、能够进行组播、单播并且处于up状态的活动网口。<br/>
            如果没有找到可用网口，请检查是否名称不匹配、标志位异常或者尚未正常启动。
          </div>
        )
      },
      {
        title: 'Core启动提示"query timeout"',
        answer: (
          <div>
            Core内置了ImageServer模块，需要使用组播协议进行通讯，Installer安装时已经在Firewalld上配置了允许组播报文。但是已知部分用户关闭firewalld导致该规则不生效，而产生本报错对于这种场景请打开firewalld；使用iptables的用户如果有相同问题，请手动添加相应规则。
          </div>
        )
      },
      {
        title: 'Cell启动提示"query timeout"',
        answer: (
          <div>
            Cell模块启动时需要通过连接Core模块完成自我发现和组网，请检查Core模块是否启动。如果已经启动，请检查服务器网络配置和模块的通讯域配置信息是否一致
          </div>
        )
      },
      {
        title: '创建云主机时提示资源不足，比如：no cell has enough disk',
        answer: (
          <div>
            <p>
            Nano创建云主机时，会从指定资源池的各节点中根据请求的云主机配置和实时负载，选择最合适的节点部署云主机。<br/>
            上述错误表示目前没有资源节点可以满足请求的要求，通常会有以下几种情况：
            </p>
            <ol>
              <li>资源池中尚未添加资源节点。请添加节点后重新尝试</li>
              <li>资源池中有可用节点，但是节点被禁用或者处于离线状态。请恢复节点后重新尝试</li>
              <li>资源池中有资源节点，但是没有任何节点的可用资源满足创建要求（Nano会为操作系统正常运行保留部分资源）</li>
              <li>节点资源充足，但是磁盘空间未正确分配给Nano。请检查仪表盘上的资源池可用磁盘容量，使用lvm为"/"路径增加磁盘空间或者修改cell节点的存储路径</li>
            </ol>
          </div>
        )
      },
      {
        title: '从磁盘镜像克隆创建新云主机时，提示resize image fail',
        answer: (
          <div>
            Nano克隆云主机时，会先将源磁盘镜像复制为新主机系统磁盘，然后调用qemu-img指令将磁盘大小调整为用户要求的系统磁盘尺寸。<br/>
            如果新建云主机的系统磁盘容量小于磁盘镜像的原始大小，则可能出现该错误。<br/>
            请尝试创建系统盘容量更大的云主机或者构建更小尺寸的磁盘镜像。
          </div>
        )
      },
      {
        title: '启动云主机或者使用镜像时，系统提示权限不足：Permission denied',
        answer: (
          <div>
            Nano使用qemu启动云主机实例，如果qemu启动时使用的用户帐号没有权限访问云主机文件，则可能产生该报错。<br/>
            该错误通常是由于用户在使用Installer安装Nano之前已经安装了qemu，qemu服务绑定了默认帐号，而Nano创建的磁盘文件通常是root用户，导致qemu用户无法访问。<br/>
            可以检查“/etc/libvirt/qemu.conf”文件定位并修复该问题
          </div>
        )
      },
      {
        title: 'Nano安装之后能否使用yum更新系统软件',
        answer: (
          <div>
            可以<br/>
            Nano使用go编译为独立的二进制程序，无特殊外部依赖要求，宿主机可以使用yum进行日常的更新维护。
          </div>
        )
      },
      {
        title: 'Nano的服务能否配置成开机启动',
        answer: (
          <div>
            可以<br/>
            Nano所有模块均支持在任意位置调用，可以使用绝对路径配置在rc.local或者自行编写systemd服务实现开机启动。
          </div>
        )
      },
      {
        title: '升级0.9.1之后看不到原有云主机和镜像了',
        answer: (
          <div>
            <p>
              由于新版本只能查看自己拥有的云主机和镜像，请执行以下指令修改资源归属并重启服务，否则会无法看到自己的云主机和镜像资源。以当前用户为nano，用户组为admin为例
            </p>
            <Box bgcolor='secondary.main'>
              <blockquote>
                更新Core模块镜像归属<br/>
                $sed -i 's/\"owner\": \"admin\"/\"owner\": \"nano\"/' /opt/nano/core/data/image.data<br/>
                $sed -i 's/\"group\": \"manager\"/\"group\": \"admin\"/' /opt/nano/core/data/image.data<br/>
                <br/>
                更新Cell模块云主机归属<br/>
                $sed -i 's/\"user\": \"admin\"/\"user\": \"nano\"/' /opt/nano/cell/data/instance.data<br/>
                $sed -i 's/\"group\": \"manager\"/\"group\": \"admin\"/' /opt/nano/cell/data/instance.data<br/>
              </blockquote>
            </Box>
          </div>
        )
      },
    ],
  },
  'en': {
      api: 'API References',
      apiFrontEndExternal: 'FrontEnd REST API - Online Manual (Detail version)',
      apiFrontEndExternalURL: 'https://frontenden.docs.apiary.io/',
      apiFrontEndInternal: 'FrontEnd REST API - Local Manual (Brief version)',
      apiFrontEndInternalURL: '/api/frontend_api_en.html',
      apiFrontEndRaw: 'FrontEnd REST API - Raw File（API Blueprint）',
      apiFrontEndRawURL: '/api/frontend_api_en.apib',
      community: 'Community',
      communityDescription: (
        <div>
          All issues or PR on <Link href='https://github.com/project-nano'>Github</Link> are appreciated and welcome.
        </div>
      ),
      title: 'Documents&Support',
      faqs: 'FAQ',
      links: 'Resource Links',
      resources: [
        {
          name: 'User Manual(*Important)',
          url: 'https://nanocloud.readthedocs.io/projects/guide/en/latest/'
        },
        {
          name: 'Features',
          url: '/en/features'
        },
        {
          name: 'White Paper(pdf)',
          url: '/files/nano_white_paper_en.pdf'
        },
        {
          name: 'Github Repository',
          url: 'https://github.com/project-nano'
        },
        {
          name: 'Wiki',
          url: 'https://github.com/project-nano/releases/wiki'
        }
      ],
      questions: [
        {
          title: 'What can Nano do for me?',
          answer: (
            <div>
              <p>
              Nano is a virtualization platform, which can convert your physical server cluster into a computing resource pool, and dynamically allocate and manage cloud hosts on top of them, effectively improving the resource utilization efficiency of IT facilities and saving management costs.
              </p>
              <p>
              Nano provides a series of management tools from resource monitoring, instance management, mirroring to snapshots to improve the efficiency of operation and maintenance engineers.
              </p>
              <p>
                Nano provides complete API and front-end code. Customize your own, or integrate into your project.
              </p>
            </div>
          )
        },
        {
          title: 'Install requirements for Nano?',
          answer: (
            <ul>
              <li>Virtualization enabled X86 servers, or nested virtualization enabled virtual machines</li>
              <li>2 cores/4 GB memory/50 GB disk/1 network interface</li>
              <li>CentOS 7 Minimal</li>
              <li>Operation system installed with network ready</li>
            </ul>
          )
        },
        {
          title: 'How to install?',
          answer: (
            <div>See <Link href='/en/quick_start'>Quick Start Guide</Link> page for details. <br/>
            The installation will usually take three minutes.</div>
          )
        },
        {
          title: 'Can Nano be installed on a virtual machine?',
          answer: (
            <div>
              <p>
              All products enable nesting virtualization is possible in theoretically.<br/>Tested products:
              </p>
              <ul>
                <li>VMware Station, test OK with Intell VT-x/AMD-V enabled</li>
                <li>VMware ESXi, test OK with Promiscuous Mode</li>
                <li>VirtualBox, test fail</li>
              </ul>
            </div>
          )
        },
        {
          title: 'Network/SSH disconnected when installing Nano',
          answer: (
            <div>
              <p>
                The installer will configure network bridge and restart network service during installation, which does not affect the network connection for most Dell series servers and VMware instances.
              </p>
              <p>
                However, it is true that some servers may cause network disconnection due to network drivers, which should install using the server's IPMI or similar remote administration interface instead of SSH.
              </p>
            </div>
          )
        },
        {
          title: 'Installer prompt "firewalld service stopped/disabled"',
          answer: (
            <div>
              Nano needs to configure the firewalld service to allow multicast. Make sure firewalld service enabled and running for installing Nano.
            </div>
          )
        },
        {
          title: 'Prompt "no default route available" when Installer or Cell starts',
          answer: (
            <div>
              Nano requires a default route configured to work, manually configure a new one and restarted. <br/>
              Assuming that the default gateway in the network is 192.168.1.1, execute below command.<br/>
              <Box bgcolor='secondary.main'>
                <blockquote>
                  $ ip route add default via 192.168.1.1
                </blockquote>
              </Box>
            </div>
          )
        },
        {
          title: 'Installer prompt "firewalld service stopped/disabled"',
          answer: (
            <div>
              The Installer must configure the Firewalld to allow multicast communication, or the modules will fail to discover each other later.
            </div>
          )
        },
        {
          title: 'The Installer can\'t find any available network interface in the installation',
          answer: (
            <div>
              During the installation, the Installer will list all active interfaces of the host with the prefix "em" that enable multicast and unicast.<br/>
              If no interface available, please check the name, flag, or up status of host interfaces.
            </div>
          )
        },
        {
          title: 'Prompt "query timeout" when starting Core',
          answer: (
            <div>
            The embedded Image server in the Core requires the multicast protocol allowed in the firewalld to work, which already configured by the Installer.
            However, it knowns that some users turn off the firewalld to cause this error. In this case, please turn on the firewalld. For users who use the Iptables, please manually add a corresponding rule.
            </div>
          )
        },
        {
          title: 'Prompt "query timeout" when starting Cell',
          answer: (
            <div>
              The Cell requires a running Core process to complete self-discovery and networking. Check if the Core module and network are running correctly or the domain parameters are identical to Core's configure.
            </div>
          )
        },
        {
          title: 'Prompt no enough resource when creating the instance, like "no cell has enough disk ..." etc.',
          answer: (
            <div>
              <p>
              When creating a new instance, the platform will choose the most appropriate node to deploy from each node of the target resource pool base on the requirement and system load. <br/>
              The above error indicates that there are no nodes that can meet the requirements currently. There are usually the following situations:
              </p>
              <ol>
                <li>No resource node available in the resource pool, first add a cell node to the resource pool and try again.</li>
                <li>Nodes available in the resource pool, but all nodes are disabled or offline, please resume the node and try again.</li>
                <li>Resource nodes available in the resource pool, but none of them fulfill the resource requirements (Nano reserves some resources for the operating system)</li>
                <li>The resource is sufficient, but disk space is not assigned correctly to the Cell. Check the available disk capacity on the dashboard. Reallocate the disk for the mounted path: /, or modify the storage location of the Cell.</li>
              </ol>
            </div>
          )
        },
        {
          title: 'Prompt "resize image fail ..." when cloning a new instance from a disk image',
          answer: (
            <div>
            When cloning a new instance, it first copies the source image as the new system volume, then calls the 'qemu-img' instruction to resize the disk to the requested size. <br/>
            This error may occur if the disk capacity of the system volume is smaller than the original image. <br/>
            Try to create an instance with a larger system volume or build a smaller disk image to fix this problem.
            </div>
          )
        },
        {
          title: 'Prompt insufficient privilege like "Permission denied ..." when starting an instance or using an image.',
          answer: (
            <div>
              Nano uses QEMU to start the instance, and the QEMU service has a bound Unix user to operate. This error may occur if this user does not have permission to access the disk files.<br/>
              This problem usually happens when the QEMU installs using its default user 'qemu' first, then the root user executes the Nano Installer to create image paths. In this case, the 'qemu' user can not access the files created by the root.<br/>
              Check the file "/etc/libvirt/qemu.conf" to verify and fix the problem.
            </div>
          )
        },
        {
          title: 'Can I use the yum to update system software after installing Nano?',
          answer: (
            <div>
              Yes.<br/>
              Every Nano module is a standalone binary program that doesn't depend on any specified library. You can use yum for daily updates and maintenance easily.
            </div>
          )
        },
        {
          title: 'Could the Nano module auto startup when the server up?',
          answer: (
            <div>
              Yes.<br/>
              Every module of Nano could invoke from anywhere, you can configure an absolute path in file 'rc.local' or write your systemd service for auto startup.
            </div>
          )
        },
        {
          title: 'All instances and images absent after upgrading 0.9.1',
          answer: (
            <div>
              <p>
                Since the new version can only view the instances and images you created, execute the following instructions to modify the ownership of resources and restart the modules, otherwise you will not be able to see your instances and images.
                Take the user 'nano' and group 'admin' as an example:
              </p>
              <Box bgcolor='secondary.main'>
                <blockquote>
                  Update ownership of images in the Core module<br/>
                  $sed -i 's/\"owner\": \"admin\"/\"owner\": \"nano\"/' /opt/nano/core/data/image.data<br/>
                  $sed -i 's/\"group\": \"manager\"/\"group\": \"admin\"/' /opt/nano/core/data/image.data<br/>
                  <br/>
                  Update ownership of instances in the Cell module<br/>
                  $sed -i 's/\"user\": \"admin\"/\"user\": \"nano\"/' /opt/nano/cell/data/instance.data<br/>
                  $sed -i 's/\"group\": \"manager\"/\"group\": \"admin\"/' /opt/nano/cell/data/instance.data<br/>
                </blockquote>
              </Box>
            </div>
          )
        },
      ],
  }
}

export default function(props){
  const defaultLang = 'cn';
  const lang = props.pageContext.lang || defaultLang;
  const texts = i18n[lang];
  return (
      <Layout title={texts.title} lang={lang}>
        <Container maxWidth="md">
          <Box p={2}>
            <Box m={2}>
              <Typography variant='h5'>
                {texts.api}
              </Typography>
            </Box>
            <Box m={2}>
              <ul>
                <li><Link href={texts.apiFrontEndExternalURL} underline='none' target="_blank" rel="noreferrer">{texts.apiFrontEndExternal}</Link></li>
                <li><Link href={texts.apiFrontEndInternalURL} underline='none' target="_blank" rel="noreferrer">{texts.apiFrontEndInternal}</Link></li>
                <li><Link href={texts.apiFrontEndRawURL} underline='none' target="_blank" rel="noreferrer">{texts.apiFrontEndRaw}</Link></li>
              </ul>
            </Box>
            <Divider/>
          </Box>
          <Box p={2}>
            <Box m={2}>
              <Typography variant='h5'>
                {texts.faqs}
              </Typography>
            </Box>
            <Box m={2}>
              <ThemeProvider theme={faqTheme}>
              {
                texts.questions.map(q => (
                  <ExpansionPanel key={q.title}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{q.title}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography component='div'>
                        {q.answer}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))
              }
              </ThemeProvider>
            </Box>
            <Divider/>
          </Box>
          <Box p={2}>
            <Box m={2}>
              <Typography variant='h5'>
                {texts.community}
              </Typography>
            </Box>
            <Box m={2}>
              {texts.communityDescription}
            </Box>
            <Divider/>
          </Box>
          <Box p={2}>
            <Box m={2}>
              <Typography variant='h5'>
                {texts.links}
              </Typography>
            </Box>
            <Box m={2}>
            <Grid container spacing={2}>
              {
                texts.resources.map(link => (
                  <Grid item xs={12} sm={3} key={link.url}>
                      <Link href={link.url}>{link.name}</Link>
                  </Grid>
                ))
              }
            </Grid>
            </Box>
          </Box>
        </Container>
      </Layout>
  );
}
